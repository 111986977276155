import "../styles/quiz.scss"

window.addEventListener("hashchange", (e) => {
  console.log("hash", window.location.hash)
  if (window.location.hash.startsWith("#quiz:")) {
    e.preventDefault()
    const form = document.querySelector(".quiz")
    if (!form)
      return
    const id = window.location.hash.replace("#quiz:", "")
    form.querySelector("[name=id]").value = id
    history.pushState("", document.title, window.location.pathname + window.location.search);
    document.querySelectorAll(".modal._visible").forEach(el => el.classList.remove("_visible"))
    document.getElementById("popup-banner-container")?.classList.remove("_visible")
    twinspark.trigger(form, "submit")
  }
}, false)
